import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import { Image } from '~/components/elements/Image';
import { ArticleRenderer } from '~/components/layouts/ArticleRenderer';
import { NewsletterForm } from '~/components/elements/forms/NewsletterForm';
import { Button, ButtonType, ButtonSize, ButtonColor } from '~/components/elements/button/Button';
import { Icon, IconType } from '~/components/elements/Icon';
import { ArticleList } from '~/components/flexible/ArticleList';
import Date from '~/components/elements/Date';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function articlePage({
  data: { wpPage, wpPost, allWpPost, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const author = wpPost.author.node;

  return (
    <Layout wp={wp}>
      <Seo post={wpPost} title={wpPost.title} />
      <div className="section !pt-20 lg:!pt-24">
        <div className="container-lg">
          <figure className="w-full rounded-lg overflow-hidden max-h-[580px] safari-overflow">
            <Image
              image={wpPost.postTypePost.mastheadImage}
              objectFit="cover"
              objectPosition="center"
              className="max-h-[580px]"
            />
          </figure>
        </div>
        <div className="container md:grid grid-cols-12 gap-12 lg:gap-24 mt-10 md:mt-18 lg:mt-[144px]">
          <div className="md:order-2 md:col-span-6 lg:col-span-8 mt-10 md:mt-0">
            <header className="mb-10">
              <p className="mb-4 font-bold text-forest">
                {wpPost.categories.nodes.map(({ name }, i) => (
                  <span className="mr-4" key={i}>
                    {name}
                  </span>
                ))}
              </p>
              <h1 className="mb-4 text-h3">{wpPost.title}</h1>
              <p className="text-mineral">
                <Date dateString={wpPost.date} /> • {wpPost.postTypePost.readLength}
              </p>
            </header>
            <ArticleRenderer prefix="Post_Posttypepost_ArticleBlocks_" content={wpPost?.postTypePost?.articleBlocks} />
            <div className="px-8 py-6 lg:px-12 mt-12 bg-white border border-periwinkle items-center rounded-lg md:flex gap-7 ">
              <figure className="my-2 flex-none w-[50px] h-[50px] rounded-full overflow-hidden bg-fountain">
                <Image image={author.userProfile.profilePicture} />
              </figure>
              <figcaption className="my-2 flex flex-col justify-start text-left">
                <span className="font-bold text-xl">
                  {author.name} {author.lastName}
                </span>
                <span className="text-mineral">{author.userProfile.userRole}</span>
              </figcaption>
              <ul className="my-2 flex md:justify-end gap-3 md:gap-6 text-forest grow">
                {author.userProfile.socialMedia.facebook.url && (
                  <li>
                    <a
                      href={author.userProfile.socialMedia.facebook.url}
                      target={author.userProfile.socialMedia.facebook.target}
                      title="Facebook"
                      className="hover:text-fountain transition-all"
                    >
                      <i className="block w-[20px] h-[20px] fw-icon">
                        <Icon type={IconType.Facebook} />
                      </i>
                    </a>
                  </li>
                )}
                {author.userProfile.socialMedia.twitter.url && (
                  <li>
                    <a
                      href={author.userProfile.socialMedia.twitter.url}
                      target={author.userProfile.socialMedia.twitter.target}
                      title="Twitter"
                      className="hover:text-fountain transition-all"
                    >
                      <i className="block w-[20px] h-[20px] fw-icon">
                        <Icon type={IconType.Twitter} />
                      </i>
                    </a>
                  </li>
                )}
                {author.userProfile.socialMedia.linkedin.url && (
                  <li>
                    <a
                      href={author.userProfile.socialMedia.linkedin.url}
                      target={author.userProfile.socialMedia.linkedin.target}
                      title="Linkedin"
                      className="hover:text-fountain transition-all"
                    >
                      <i className="block w-[20px] h-[20px] fw-icon">
                        <Icon type={IconType.LinkedIn} />
                      </i>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="md:col-span-6 md:order-1 lg:col-span-4 mt-10 md:mt-0">
            <aside className="sticky top-24">
              <p className="mt-4">
                <span className="py-2 px-5 bg-deepsea font-bold text-white rounded-full text-lg transition-all">
                  Latest articles
                </span>
              </p>

              <ul className="mt-10">
                {allWpPost.nodes.slice(0, 3).map(({ title, uri, postTypePost }, i) => (
                  <li className="block my-4" key={i}>
                    <a href={uri} className="flex items-center gap-5 w-full">
                      <figure className="rounded-md overflow-hidden w-[65px]">
                        <Image image={postTypePost.previewImage} />
                      </figure>
                      <p className="font-bold">{title}</p>
                    </a>
                  </li>
                ))}
              </ul>
              <div className="mt-16 p-8 bg-deepsea rounded-lg text-white">
                <h4 className="font-body font-bold text-lg lg:text-[24px] mb-4 text-center">Don’t miss a thing</h4>
                <NewsletterForm buttonColour="zest" inlineFlex={true} />
              </div>
            </aside>
          </div>
        </div>
        {wpPost?.postTypePost?.latestPosts?.length > 0 && (
          <ArticleList
            columns="3"
            heading="There's more"
            headingSize="text-h3"
            showCategories={false}
            listItems={wpPost?.postTypePost?.latestPosts}
          />
        )}
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
      categories {
        nodes {
          uri
          name
        }
      }
      author {
        node {
          name
          lastName
          userProfile {
            userRole
            profilePicture {
              localFile {
                publicURL
              }
            }
            socialMedia {
              facebook {
                target
                url
              }
              linkedin {
                url
                target
              }
              twitter {
                url
                target
              }
            }
          }
        }
      }
    }
    wp {
      ...GeneratedWp
    }
    allWpPost {
      nodes {
        title
        uri
        date
        categories {
          nodes {
            uri
            name
          }
        }
        postTypePost {
          previewExcerpt
          previewImage {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
