import React, { useState } from 'react';
import { Link } from '~/components/elements/Link';
import { Image } from '~/components/elements/Image';
import { Icon, IconType } from '~/components/elements/Icon';
import Date from '~/components/elements/Date';

export const ArticleList = ({ listItems, columns, heading, headingSize, showCategories, featurePosts }) => {
  let cols = 'sm:grid grid-cols-2';
  if (columns === '3') {
    cols = 'sm:grid grid-cols-12';
  }
  let headingMargin = '';

  const [activeCat, setActiveCat] = useState('All');
  const filterItems = ['All'];

  //Map through categories in use and add the filterItems
  listItems?.map(({ categories }) => {
    categories?.nodes.map(({ name }) => {
      if (!filterItems.includes(name)) filterItems.push(name);
    });
  });

  if (activeCat === 'All') {
    listItems = listItems?.filter((listItem) => {
      let match = true;
      return match;
    });
  } else {
    listItems = listItems.filter((listItem) => {
      let match = false;
      listItem?.categories?.nodes.map((name) => {
        if (name.name == activeCat) match = true;
      });
      return match;
    });
  }

  return (
    <section className="my-10 md:mt-16 lg:mt-20">
      <div className="container">
        {heading && <h2 className={` ${headingSize ? headingSize : 'text-h5'} ` + headingMargin}>{heading}</h2>}
        {showCategories && (
          <ul>
            {filterItems?.map((name) => {
              const active = name === activeCat;
              return (
                <li key={name} onClick={() => setActiveCat(name)} className="inline-block my-2 mr-2 font-bold lg:mr-4">
                  <span
                    className={`${
                      active
                        ? 'bg-sea text-pampas'
                        : 'bg-pampas hover:bg-sea hover:text-pampas transition cursor-pointer'
                    }  py-[6px] px-4  rounded-full`}
                  >
                    {name}
                  </span>
                </li>
              );
            })}
          </ul>
        )}
        {listItems && (
          <ul className={`sm:grid ${cols} gap-6`}>
            {listItems.map((listItem, i) => (
              <ArticleItem key={i} {...listItem} featurePosts={featurePosts} inc={i} />
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export const ArticleItem = ({ uri, title, postTypePost, date, categories, featurePosts, inc }) => {
  let headingClasses = 'text-h5 text-forest';
  if (date) {
    headingClasses = 'text-h5 lg:text-[28px] text-deepsea';
  }
  return (
    <li
      className={`my-8 hover:opacity-60 transition ease-in-out duration-200
    ${featurePosts && inc <= 1 ? 'lg:col-span-6' : 'lg:col-span-4'}
    col-span-12 md:col-span-6`}
    >
      <a href={uri} title={title}>
        {postTypePost?.previewImage && (
          <figure className="mb-4 overflow-hidden rounded-md lg:mb-8 post-ratio">
            <Image image={postTypePost?.previewImage} />
          </figure>
        )}
        <div className="max-w-lg">
          <p className="mb-4 text-sm">
            {categories && (
              <>
                {categories?.nodes.map(({ name }, i) => (
                  <span key={i} className="font-bold text-forest">
                    {name + ' • '}
                  </span>
                ))}
              </>
            )}
            <span>
              <Date dateString={date} />
            </span>
          </p>

          <h3 className={'mb-3 ' + headingClasses}>{title}</h3>
          <div
            className={'mb-3 prose ' + (date ? '' : 'text-xl')}
            dangerouslySetInnerHTML={{ __html: postTypePost?.previewExcerpt }}
          />
          {!date && <Link faux className="text-lg font-bold text-forest" title={'Read story'} url={uri} iconAfter />}
        </div>
      </a>
    </li>
  );
};
