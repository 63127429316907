import React from 'react';
import { Image } from '~/components/elements/Image';

export const ArticleImage = (props) => {

  return (
    <figure className="rounded-lg overflow-hidden my-8 md:my-16">
      <Image image={props.image} />
    </figure>
  );
};