import React from 'react';

export const Bullets = (props) => {

  const {bullets} = props

  return (
    <div className="my-8 md:my-16 bg-white rounded-lg p-8 lg:px-20 lg:py-14">
      <h3 className="text-lg font-body font-bold mb-8 ">{props.heading}</h3>
      <div className="prose">
        <ul>
          {bullets.map(({text},i ) =>
            <li key={i}>{text}</li>
          )}
        </ul>
      </div>
    </div>
  );
};