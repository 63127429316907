import React from 'react';
import { Text } from '~/components/flexible/article/Text';
import { ArticleImage } from '~/components/flexible/article/ArticleImage';
import { Quote } from '~/components/flexible/article/Quote';
import { Bullets } from '~/components/flexible/article/Bullets';

export const ArticleRenderer = ({ prefix, content = [] }) => {
  return (
    <>
      {content?.map((layout, i) => {
        const layoutName = layout.fieldGroupName.replace(prefix, '');
        if (layoutName === 'Text') {
          return <Text key={i} {...layout} />;
        } else if (layoutName === 'Image') {
          return <ArticleImage key={i} {...layout} />;
        } else if (layoutName === 'Quote') {
          return <Quote key={i} {...layout} />;
        } else if (layoutName === 'Bullets') {
          return <Bullets key={i} {...layout} />;
        } else {
          console.log('IMPLEMENT ', layoutName, layout);
        }
        return null;
      })}
    </>
  );
};