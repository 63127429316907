import React from 'react';
import { Image } from '~/components/elements/Image';

export const Quote = (props) => {

  const {quote} = props
  return (
    <>
      {props.layout === '1' ?(
        <figure className="my-6 py-8 md:py-12 border-periwinkle border-t border-b">
          <div className="max-w-[90px] mb-6 md:mb-10">
            <Image image={props.logo} />
          </div>
          <blockquote className="font-heading text-l md:text-xl mb-3">
            {props.quote}
          </blockquote>
          <figcaption className="mt-6 md:mt-10">
            <span className="font-bold">{props.by.name}, </span>
            <span>{props.by.role}</span>
          </figcaption>
        </figure>
      ) : (
          <figure className="my-6 p-8 lg:p-12 mt-12 bg-white border border-periwinkle items-center rounded-lg">
          <div className="max-w-[90px] mb-6 md:mb-10">
            <Image image={props.logo} />
          </div>
          <blockquote className="font-heading text-l md:text-xl mb-3">
            {props.quote}
          </blockquote>
          <figcaption className="mt-6 md:mt-10">
            <span className="font-bold">{props.by.name}, </span>
            <span>{props.by.role}</span>
          </figcaption>
        </figure>
      )}
    </>
  );
};